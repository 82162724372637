<template>
  <div id="header-mobile" class="header">
    <div class="header__left">
      <BaseImg src="/assets/images/logo.svg" alt="logo" class="logo" @click="navigateTo(PAGE.HOME)" />
    </div>
    <div class="header__right">
      <client-only>
        <UserLoginBlock v-if="isLogged" />
        <UserNotLoginBlock v-else />
      </client-only>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import UserLoginBlock from './user-login-block.vue'
import UserNotLoginBlock from './user-not-login-block.vue'
import { useAppStore } from '@/store/app'
import { PAGE } from '~/constants/router'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/layouts/header/index.scss"></style>
