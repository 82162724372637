<template>
  <div
    class="menu-item"
    :class="[
      {
        active:
          isActive ||
          (item.link === PAGE.SUPPORT &&
            ($route.fullPath.includes(item.link) || $route.fullPath.includes(PAGE.GUIDE))) ||
          (item.class === 'account' && $route.fullPath.includes(item.link))
      },
      item.class
    ]"
    @click.prevent="openLink(item)"
  >
    <div class="menu-item__icon" :class="[{ logged: isLogged }]">
      <BaseImg :src="isActive ? item.iconActive : item.icon" class="img-fluid" />
    </div>
    <div class="d-block menu-item__text">
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { PAGE } from '~/constants/router'
import useHeader from '~/composables/useHeader'
import { IMenuItemMb } from '~/types/home'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const route = useRoute()

const isActive = computed(() => route.fullPath === props.item.link)

const { onClickAuthor, MODAL_TYPES } = useHeader()

const props = withDefaults(defineProps<{ item: IMenuItemMb }>(), {})

const openLink = (item: IMenuItemMb) => {
  if (isLogged.value || !item.loginRequired) {
    navigateTo(item.link)
  } else {
    onClickAuthor(MODAL_TYPES.LOGIN, 'login')
  }
}
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/layouts/footer/menu-item.scss"></style>
